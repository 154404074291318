/* fira-sans-100 - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Fira Sans";
  font-style: normal;
  font-weight: 100;
  src: url("fonts/fira-sans-v16-latin-100.eot"); /* IE9 Compat Modes */
  src: url("fonts/fira-sans-v16-latin-100.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-IE8 */ url("fonts/fira-sans-v16-latin-100.woff2") format("woff2"),
    /* Super Modern Browsers */ url("fonts/fira-sans-v16-latin-100.woff")
      format("woff"),
    /* Modern Browsers */ url("fonts/fira-sans-v16-latin-100.ttf")
      format("truetype"),
    /* Safari, Android, iOS */ url("fonts/fira-sans-v16-latin-100.svg#FiraSans")
      format("svg"); /* Legacy iOS */
}
/* fira-sans-200 - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Fira Sans";
  font-style: normal;
  font-weight: 200;
  src: url("fonts/fira-sans-v16-latin-200.eot"); /* IE9 Compat Modes */
  src: url("fonts/fira-sans-v16-latin-200.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-IE8 */ url("fonts/fira-sans-v16-latin-200.woff2") format("woff2"),
    /* Super Modern Browsers */ url("fonts/fira-sans-v16-latin-200.woff")
      format("woff"),
    /* Modern Browsers */ url("fonts/fira-sans-v16-latin-200.ttf")
      format("truetype"),
    /* Safari, Android, iOS */ url("fonts/fira-sans-v16-latin-200.svg#FiraSans")
      format("svg"); /* Legacy iOS */
}
/* fira-sans-regular - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Fira Sans";
  font-style: normal;
  font-weight: 400;
  src: url("fonts/fira-sans-v16-latin-regular.eot"); /* IE9 Compat Modes */
  src: url("fonts/fira-sans-v16-latin-regular.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-IE8 */ url("fonts/fira-sans-v16-latin-regular.woff2") format("woff2"),
    /* Super Modern Browsers */ url("fonts/fira-sans-v16-latin-regular.woff")
      format("woff"),
    /* Modern Browsers */ url("fonts/fira-sans-v16-latin-regular.ttf")
      format("truetype"),
    /* Safari, Android, iOS */
      url("fonts/fira-sans-v16-latin-regular.svg#FiraSans") format("svg"); /* Legacy iOS */
}
/* fira-sans-500 - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Fira Sans";
  font-style: normal;
  font-weight: 500;
  src: url("fonts/fira-sans-v16-latin-500.eot"); /* IE9 Compat Modes */
  src: url("fonts/fira-sans-v16-latin-500.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-IE8 */ url("fonts/fira-sans-v16-latin-500.woff2") format("woff2"),
    /* Super Modern Browsers */ url("fonts/fira-sans-v16-latin-500.woff")
      format("woff"),
    /* Modern Browsers */ url("fonts/fira-sans-v16-latin-500.ttf")
      format("truetype"),
    /* Safari, Android, iOS */ url("fonts/fira-sans-v16-latin-500.svg#FiraSans")
      format("svg"); /* Legacy iOS */
}
/* fira-sans-600 - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Fira Sans";
  font-style: normal;
  font-weight: 600;
  src: url("fonts/fira-sans-v16-latin-600.eot"); /* IE9 Compat Modes */
  src: url("fonts/fira-sans-v16-latin-600.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-IE8 */ url("fonts/fira-sans-v16-latin-600.woff2") format("woff2"),
    /* Super Modern Browsers */ url("fonts/fira-sans-v16-latin-600.woff")
      format("woff"),
    /* Modern Browsers */ url("fonts/fira-sans-v16-latin-600.ttf")
      format("truetype"),
    /* Safari, Android, iOS */ url("fonts/fira-sans-v16-latin-600.svg#FiraSans")
      format("svg"); /* Legacy iOS */
}
/* fira-sans-700 - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Fira Sans";
  font-style: normal;
  font-weight: 700;
  src: url("fonts/fira-sans-v16-latin-700.eot"); /* IE9 Compat Modes */
  src: url("fonts/fira-sans-v16-latin-700.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-IE8 */ url("fonts/fira-sans-v16-latin-700.woff2") format("woff2"),
    /* Super Modern Browsers */ url("fonts/fira-sans-v16-latin-700.woff")
      format("woff"),
    /* Modern Browsers */ url("fonts/fira-sans-v16-latin-700.ttf")
      format("truetype"),
    /* Safari, Android, iOS */ url("fonts/fira-sans-v16-latin-700.svg#FiraSans")
      format("svg"); /* Legacy iOS */
}
/* fira-sans-800 - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Fira Sans";
  font-style: normal;
  font-weight: 800;
  src: url("fonts/fira-sans-v16-latin-800.eot"); /* IE9 Compat Modes */
  src: url("fonts/fira-sans-v16-latin-800.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-IE8 */ url("fonts/fira-sans-v16-latin-800.woff2") format("woff2"),
    /* Super Modern Browsers */ url("fonts/fira-sans-v16-latin-800.woff")
      format("woff"),
    /* Modern Browsers */ url("fonts/fira-sans-v16-latin-800.ttf")
      format("truetype"),
    /* Safari, Android, iOS */ url("fonts/fira-sans-v16-latin-800.svg#FiraSans")
      format("svg"); /* Legacy iOS */
}
/* fira-sans-900 - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Fira Sans";
  font-style: normal;
  font-weight: 900;
  src: url("fonts/fira-sans-v16-latin-900.eot"); /* IE9 Compat Modes */
  src: url("fonts/fira-sans-v16-latin-900.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-IE8 */ url("fonts/fira-sans-v16-latin-900.woff2") format("woff2"),
    /* Super Modern Browsers */ url("fonts/fira-sans-v16-latin-900.woff")
      format("woff"),
    /* Modern Browsers */ url("fonts/fira-sans-v16-latin-900.ttf")
      format("truetype"),
    /* Safari, Android, iOS */ url("fonts/fira-sans-v16-latin-900.svg#FiraSans")
      format("svg"); /* Legacy iOS */
}
