/*!

=========================================================
* NextJS Material Kit v1.0.0 based on Material Kit Free - v2.0.2 (Bootstrap 4.0.0 Final Edition) and Material Kit React v1.8.0
=========================================================

* Product Page: https://www.creative-tim.com/product/nextjs-material-kit
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/nextjs-material-kit/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

@import 'core/variables';
@import 'core/mixins';

// Core Components
@import 'core/misc';
@import 'core/page-transition';

// Core Plugins

// @import "plugins/plugin-datetime-picker";
@import 'plugins/plugin-nouislider';
@import 'plugins/plugin-react-datetime';
@import 'plugins/plugin-react-slick';
